import "./jquery.bxslider.js";

export default function() {

    const sliderLoad = function() {
        $('.carousel__slide').css('visibility', 'visible')
        $('.carousel__slide').each(
            function() {
                var $el = $(this).find('img');
                var $attrData = $el.attr("data-src")
                $el.attr("src", $attrData);
            }
        )
    }

    $('.carousel--hero').bxSlider({
        mode: 'fade',
        wrapperClass: "m-banner-hero__banner-container carousel",
        onSliderLoad: sliderLoad,
        responsive: false,
        auto: true,
        autoStart: true,
        pause: 8000,
        pager: false
    });

    $('.carousel--single').bxSlider({
        mode: 'fade',
        wrapperClass: "m-awards__press-awards__item carousel",
        onSliderLoad: sliderLoad,
        responsive: false,
        auto: true,
        autoStart: true,
        pause: 4000,
        pager: false,
        controls: false
    });
}
